export const getPlaceByLatLng = ({ lat, lng }, cityConfig) => {
  if (!lat || !lng) return

  let uriGeocoding = 'https://geocoding.ualabee.com/reverse?format=jsonv2'

  if (window.location.hostname !== 'treep.app') {
    uriGeocoding = 'https://geocoding.ualabee.dev/reverse?format=jsonv2'
  }

  if (cityConfig?.city_id === 28) {
    uriGeocoding = 'https://nominatim.openstreetmap.org/reverse?format=jsonv2'
  }

  return fetch(`${uriGeocoding}&lat=${lat}&lon=${lng}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'variant-id': process.env.REACT_APP_VARIANT_ID
    }
  })
    .then(response => {
      if (response.ok) {
        return response.json()
      } else {
        throw new Error(`No city found by lat:${lat} long: ${lng}`)
      }
    }).then(response => {
      const address = response.address.road || response.address?.pedestrian || 'Dirección desconocida'
      const houseNumber = response.address?.house_number || 'S/N'

      return (
        {
          name: address + ' ' + houseNumber,
          latLng: { lat: Number(lat), lng: Number(lng) }
        }
      )
    })
}
