import React from 'react'
import { LogoLoader, SkeletonContainer } from '../../GlobalStyles'
import logo from '../../img/ualabeelogo.svg'
import { CircularProgress, Skeleton } from '@mui/material'
import { REACT_APP_HARDCODED_LOGO, REACT_APP_SPLASH_TYPE } from '../../constants/config'

export const SkeletonApp = () => {
  return (
    <SkeletonContainer>
      {REACT_APP_SPLASH_TYPE === 'logo' && <LogoLoader src={REACT_APP_HARDCODED_LOGO || logo} />}
      {REACT_APP_SPLASH_TYPE === 'loader' &&
        <CircularProgress
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        />}
      <Skeleton
        animation='wave'
        variant='rect'
        width='100%'
        height='100vh'
      />
    </SkeletonContainer>
  )
}
