import React, { useEffect, useState } from 'react'
import { Box, Button, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Layout } from '../../uicomponents/layouts/Layout'
import { CustomMap } from '../../uicomponents/Map'
import { theme } from '../../theme/theme'
import { useNavigate } from 'react-router-dom'
import { ArrowBackRounded } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getTicketValidationUserStats } from '../../db/getTicketValidationUserStats'
import { REACT_APP_SHOW_LOGIN } from '../../constants/config'
import UserProfilePreview from '../Desktop/UserProfilePreview'
import { getCityConfig } from '../../db/getCityConfig'
import { setCurrentCityConfig } from '../../actions/ui'
import { setUserTicketRewardStats } from '../../actions/user'
import { FAQSection } from '../RewardTickets'
import HelpRoundedIcon from '@mui/icons-material/HelpRounded'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import 'dropzone/dist/dropzone.css'
import { logEvent } from '../../firebase/firebase-config'
import SwipeableViews from 'react-swipeable-views'

export default function EarnOptions () {
  const isMobile = useMediaQuery('(max-width:480px)')
  const navigate = useNavigate()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const userPosition = useSelector(state => state?.user?.userPosition)
  const userData = useSelector(state => state?.user?.userData)
  const auth = useSelector((state) => state.user.auth)
  const cityConfig = useSelector((state) => state.ui.cityConfig)
  const userTicketRewardStats = useSelector((state) => state.user.userTicketRewardStats)

  const searchParams = new URLSearchParams(window.location.search)

  const agencyId = searchParams.get('agency_id')

  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false)
  const [faqOpen, setFaqOpen] = useState(false)

  const company = agencyId

  useEffect(() => {
    if (!cityConfig?.config?.miles_program_enabled) return navigate('/')
    if (auth && auth?.isAnonymous && !searchParams.get('custom_token')) return navigate('/login')

    const cityId = searchParams.get('city_id')

    if (!cityConfig && cityId && auth) {
      getCityConfig(cityId)
        .then((response) => {
          dispatch(setCurrentCityConfig(response))
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }, [auth, cityConfig])

  useEffect(() => {
    setLoading(true)
    const cityId = searchParams.get('city_id')

    if ((cityConfig || cityId) && auth && !auth?.isAnonymous && !userTicketRewardStats) {
      getTicketValidationUserStats(cityConfig?.city_id || cityId)
        .then((response) => {
          dispatch(setUserTicketRewardStats(response))
          setLoading(false)
        })
        .catch((err) => {
          console.error(err)
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }, [cityConfig, auth])

  const handleBack = () => {
    if (!document.startViewTransition) {
      navigate(-1)
      return
    }

    // With a View Transition:
    document.startViewTransition(() => navigate(-1))
  }

  const handleFAQOpen = () => {
    // log event
    const eventParams = {
      lat: userPosition?.lat ? userPosition.lat : null,
      lng: userPosition?.lng ? userPosition.lng : null,
      city_id: cityConfig?.city_id.toString(),
      user_id: auth.uid,
      user_birthday_timestamp: userData?.birthday?.long_value || null
    }

    logEvent('ticket_rewards_faq', eventParams)
    if (!document.startViewTransition) {
      setFaqOpen(!faqOpen)
      return
    }

    // With a View Transition:
    document.startViewTransition(() => setFaqOpen(!faqOpen))
  }

  return (
    <Layout>
      <Box
        sx={{
          width: '450px',
          height: 'calc(100% - 32px)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
          zIndex: '430',
          backgroundColor: '#ffffff',
          borderRadius: '16px',
          boxShadow: 'rgb(34 40 49 / 16%) 0px 3px 6px',
          position: 'absolute',
          top: '16px',
          left: '16px',
          padding: '16px',
          '@media (max-width:480px)': {
            width: '100%',
            position: 'fixed',
            top: 0,
            left: 0,
            height: '100%',
            borderRadius: 0,
            boxShadow: 'none',
            padding: 0
          }
        }}
      >
        <Box
          sx={{
            display: `${!searchParams.get('custom_token') ? 'flex' : 'none'}`,
            width: '100%',
            backgroundColor: '#fff',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '10px 8px',
            '@media (max-width:480px)': {
              backgroundColor: theme.palette.primary.main,
              padding: '10px 5px',
              alignItems: 'center'
            }
          }}
        >
          <Box
            sx={{
              display: `${!searchParams.get('custom_token') ? 'flex' : 'none'}`,
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'row'
            }}
          >
            <IconButton
              onClick={handleBack}
            >
              <ArrowBackRounded
                sx={{
                  color: theme.palette.icons.primary,
                  '@media (max-width:480px)': {
                    color: '#fff'
                  }
                }}
              />
            </IconButton>
            <Typography
              sx={{
                color: '#222831',
                fontSize: '16px',
                margin: '0 0 0 8px',
                '@media (max-width:480px)': {
                  color: '#fff',
                  margin: 0
                }
              }}
              variant='h1'
            >
              {!faqOpen && t('earn_options.title')}
              {faqOpen && t('tickets_rewards_program.faq_title')}
            </Typography>
          </Box>
          <IconButton
            id='faq_button'
            onClick={handleFAQOpen}
          >
            {!faqOpen &&
              <HelpRoundedIcon
                sx={{
                  color: theme.palette.icons.primary,
                  '@media (max-width:480px)': {
                    color: '#fff'
                  }
                }}
              />}
            {faqOpen &&
              <ClearRoundedIcon
                sx={{
                  color: theme.palette.icons.primary,
                  '@media (max-width:480px)': {
                    color: '#fff'
                  }
                }}
              />}
          </IconButton>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            overflow: 'auto',
            height: 'inherit',
            flexGrow: 1,
            minHeight: 0 // Important for flex layouts with overflow
          }}
        >
          {!faqOpen &&
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                padding: '16px 10px',
                gap: '20px',
                overflowY: 'auto',
                flexGrow: 1,
                minHeight: 0 // Important to prevent overflow issues
              }}
            >
              <Box
                component='img'
                src={userTicketRewardStats?.find((item) => item.agency_id === company)?.agency_logo_url}
                alt='Company Logo'
                sx={{ width: 65, height: 'auto' }}
              />
              <Typography
                variant='h1'
                sx={{
                  color: theme.palette.primary.main,
                  fontSize: '18px',
                  fontWeight: '800',
                  margin: '0'
                }}
              >
                {t('earn_options.description')}
              </Typography>

              <Carousel
                cards={userTicketRewardStats?.find((item) => item.agency_id === company)?.point_types || []}
              />
            </Box>}
          {faqOpen && <FAQSection open={faqOpen} t={t} />}
        </Box>
      </Box>
      {!isMobile && (
        <CustomMap
          showDraggableDestinationMarkers={false}
        />
      )}
      {!isMobile && REACT_APP_SHOW_LOGIN &&
        <UserProfilePreview />}
    </Layout>
  )
}

// Updated Carousel Component
const Carousel = ({ cards }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const [activeStep, setActiveStep] = useState(0)

  const isIos = useSelector(state => state?.ui?.isIosApp) ||
  (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) ||
  (navigator?.userAgentData?.platform === 'iOS')

  const handleNext = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % cards.length)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep - 1 + cards.length) % cards.length)
  }

  const handleStepChange = (step) => {
    setActiveStep(step)
  }

  return (
    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', minHeight: 0 }}>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        style={{ flexGrow: 1, minHeight: 0 }}
        containerStyle={{ height: '100%' }}
      >
        {cards.map((card, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: '#7A7A7A1A',
              borderRadius: '8px',
              flexGrow: 1,
              minHeight: 0,
              overflow: 'auto',
              padding: '16px',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {isIos && card.point_type_id.includes('TRIP_MODE')
              ? (
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    backgroundColor: '#7A7A7A1A',
                    borderRadius: '8px',
                    flexGrow: 1,
                    minHeight: 0,
                    overflow: 'auto',
                    padding: '16px'
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: 600,
                      margin: '0 0 8px 0',
                      color: theme.palette.primary.main
                    }}
                  >
                    {card.name_es}
                  </Typography>
                  {t('earn_options.coming_soon')}
                </Box>
                )
              : (
                  Math.abs(activeStep - index) <= 2 && (
                    <Box
                      sx={{ flexGrow: 1, minHeight: 0 }}
                      dangerouslySetInnerHTML={{
                        __html: card.description
                      }}
                    />
                  ))}
          </Box>
        ))}
      </SwipeableViews>
      {cards.length > 1 && (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
          <Button onClick={handleBack} sx={{ color: theme.palette.primary.main }}>
            {t('earn_options.back_button')}
          </Button>
          <Box sx={{ display: 'flex' }}>
            {cards.map((_, index) => (
              <Box
                key={index}
                sx={{
                  width: 12,
                  height: 12,
                  bgcolor: activeStep === index ? '#1B5E20' : 'grey.300',
                  borderRadius: '50%',
                  mx: 0.5,
                  transition: 'all 0.3s ease',
                  transform: activeStep === index ? 'scale(1.2)' : 'scale(1)'
                }}
              />
            ))}
          </Box>
          <Button onClick={handleNext} sx={{ color: theme.palette.primary.main }}>
            {t('earn_options.next_button')}
          </Button>
        </Box>
      )}
    </Box>
  )
}
