import React from 'react'

/**
 * Wallet Icon Component
 * @param {Object} props - Component props
 * @param {string} [props.width='24'] - Width of the icon
 * @param {string} [props.height='24'] - Height of the icon
 * @param {string} [props.fill='#181A1C'] - Fill color of the icon
 * @param {Object} [props.style] - Additional inline styles
 * @returns {JSX.Element} Wallet icon component
 */
const BusIcon = ({ width = '24', height = '24', fill = '#181A1C', style = {}, ...props }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 64 64'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={style}
      {...props}
    >
      <path d='M45.6264 45.2324C45.4913 45.4222 45.3562 45.6119 45.194 45.7475C44.5722 46.3986 43.707 46.7513 42.8148 46.7513C41.9226 46.7513 41.0574 46.3986 40.4355 45.7475C40.2734 45.6119 40.1383 45.4222 40.0032 45.2324L40.003 45.2321C39.8948 45.0693 39.7867 44.8523 39.7056 44.6624C39.5974 44.4454 39.5434 44.2283 39.4893 44.0113L39.4892 44.0108C39.4622 43.7939 39.4352 43.577 39.4352 43.3601C39.4352 43.1432 39.4622 42.9264 39.4892 42.7095L39.4893 42.7091C39.5433 42.4921 39.5974 42.2749 39.7056 42.0579C39.7867 41.868 39.8948 41.651 40.003 41.4882L40.0032 41.4879C40.1383 41.2981 40.2734 41.1083 40.4355 40.9728C41.7063 39.6977 43.9503 39.6977 45.194 40.9728C45.3562 41.1083 45.4913 41.2981 45.6264 41.4879L45.6266 41.4882C45.7347 41.651 45.8429 41.868 45.924 42.0579C46.0321 42.2749 46.0862 42.492 46.1403 42.709L46.1403 42.7094C46.1674 42.9263 46.1944 43.1432 46.1944 43.3601C46.1944 43.5771 46.1674 43.794 46.1403 44.0109L46.1402 44.0114C46.0862 44.2282 46.032 44.4455 45.924 44.6624C45.8429 44.8523 45.7347 45.0693 45.6266 45.2321L45.6264 45.2324Z' fill={fill} />
      <path d='M23.5647 45.7475C22.9428 46.3986 22.0776 46.7513 21.1854 46.7513C20.2932 46.7513 19.428 46.3986 18.8062 45.7475C18.1573 45.1235 17.8058 44.2554 17.8058 43.3601C17.8058 42.4649 18.1573 41.5967 18.8062 40.9728C20.0499 39.6977 22.321 39.6977 23.5647 40.9728C24.2135 41.5967 24.565 42.4649 24.565 43.3601C24.565 44.2554 24.2135 45.1235 23.5647 45.7475Z' fill={fill} />
      <path d='M38.7593 14.8747C39.8678 14.8747 40.787 13.9523 40.787 12.84C40.787 11.7277 39.8678 10.8054 38.7593 10.8054H25.2409C24.1324 10.8054 23.2132 11.7277 23.2132 12.84C23.2132 13.9523 24.1324 14.8747 25.2409 14.8747H38.7593Z' fill={fill} />
      <path fillRule='evenodd' clipRule='evenodd' d='M55.2587 46.9679C55.5181 46.0579 55.6572 45.0978 55.6572 44.1063V13.1113C55.6572 7.35998 50.9798 2.66663 45.248 2.66663H18.752C13.0202 2.66663 8.34286 7.35998 8.34286 13.1113V44.1063C8.34286 45.0978 8.48186 46.0578 8.7413 46.9677L8.02574 54.6888C7.86961 56.3721 8.42767 58.0554 9.58833 59.3179C10.7715 60.6024 12.468 61.3333 14.2314 61.3333H18.428C22.0442 61.3333 22.7362 59.2735 23.1827 57.9225L23.6292 56.5935L23.6372 56.5704C24.2101 54.9081 24.2857 54.6888 26.2855 54.6888H37.7146C39.7235 54.6888 39.8574 55.0653 40.3709 56.5935L40.8271 57.9517C41.2957 59.3245 41.9816 61.3333 45.5719 61.3333H49.7685C51.5321 61.3333 53.2285 60.6024 54.4116 59.3179C55.5724 58.0554 56.1305 56.3721 55.9742 54.6888L55.2587 46.9679ZM45.248 50.4817C48.7628 50.4817 51.6016 47.606 51.6016 44.1063V36.2289C50.967 36.4549 50.2844 36.578 49.5739 36.578H14.4261C13.7156 36.578 13.033 36.4549 12.3984 36.2289V44.1063C12.3984 47.6331 15.2643 50.4817 18.752 50.4817H45.248ZM14.4261 32.5086H49.5739C50.6824 32.5086 51.6016 31.5862 51.6016 30.4739V25.0481C51.6016 23.9358 50.6824 23.0134 49.5739 23.0134H14.4261C13.3176 23.0134 12.3984 23.9358 12.3984 25.0481V30.4739C12.3984 31.5862 13.3176 32.5086 14.4261 32.5086ZM14.4261 18.9441H49.5739C50.2844 18.9441 50.967 19.0672 51.6016 19.2932V13.1113C51.6016 9.58456 48.7357 6.73599 45.248 6.73599H18.752C15.2373 6.73599 12.3984 9.61169 12.3984 13.1113V19.2932C13.033 19.0672 13.7156 18.9441 14.4261 18.9441Z' fill={fill} />
    </svg>
  )
}

export default BusIcon
