import React from 'react'

/**
 * Directions Icon Component
 * @param {Object} props - Component props
 * @param {string} [props.width='24'] - Width of the icon
 * @param {string} [props.height='24'] - Height of the icon
 * @param {string} [props.fill='#181A1C'] - Fill color of the icon
 * @param {Object} [props.style] - Additional inline styles
 * @returns {JSX.Element} Directions icon component
 */
const DirectionsIcon = ({ width = '24', height = '24', fill = '#181A1C', style = {}, ...props }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 64 64'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={style}
      {...props}
    >
      <path d='M12.0265 13.8666C12.0265 15.3332 13.2265 16.5332 14.6932 16.5332C16.1865 16.5332 17.3865 15.3332 17.3865 13.8666C17.3865 12.3999 16.1865 11.1999 14.7199 11.1999H14.6932C13.1999 11.1999 12.0265 12.3999 12.0265 13.8666Z' fill={fill} />
      <path fillRule='evenodd' clipRule='evenodd' d='M9.30643 26.56C10.7731 27.9734 12.6931 28.6667 14.5864 28.6667C16.5064 28.6667 18.3998 27.9734 19.8664 26.56C24.7464 21.8934 26.7464 16.8 25.5998 11.8134C24.2664 5.97337 19.0931 3.33337 14.5864 3.33337C10.0798 3.33337 4.9331 5.94671 3.5731 11.8134C2.42643 16.8 4.39976 21.8934 9.30643 26.56ZM12.0798 23.6534C9.4131 21.12 6.4531 17.1734 7.46643 12.72C8.39976 8.74671 11.8931 7.33337 14.6131 7.33337C17.3064 7.33337 20.7998 8.74671 21.7064 12.6934C22.7464 17.1734 19.7864 21.12 17.1198 23.6534C15.7064 24.9867 13.4931 25.0134 12.0798 23.6534Z' fill={fill} />
      <path d='M46.7466 45.8666C46.7466 47.3332 47.9466 48.5332 49.4132 48.5332C50.8799 48.5332 52.1066 47.3332 52.1066 45.8666C52.1066 44.3999 50.9066 43.1999 49.4399 43.1999H49.4132C47.9199 43.1999 46.7466 44.3999 46.7466 45.8666Z' fill={fill} />
      <path fillRule='evenodd' clipRule='evenodd' d='M49.3065 60.6667C47.4132 60.6667 45.4932 59.9733 43.9998 58.56C39.0665 53.8933 37.0665 48.8 38.2398 43.7867C39.5998 37.9467 44.7732 35.3067 49.3065 35.3067C53.8398 35.3067 59.0132 37.9467 60.3732 43.7867C61.5198 48.7734 59.5198 53.8934 54.5865 58.5334C53.1198 59.9734 51.2265 60.6667 49.3065 60.6667ZM49.3065 39.3333C46.5865 39.3333 43.0665 40.7467 42.1332 44.72C41.0932 49.1734 44.0798 53.1467 46.7465 55.68C48.1598 57.04 50.3998 57.04 51.8398 55.68C54.5065 53.1467 57.4932 49.2 56.4532 44.7467C55.5732 40.7467 52.0532 39.3333 49.3065 39.3333Z' fill={fill} />
      <path d='M33.9997 50.6667C33.9997 51.76 33.093 52.6667 31.9997 52.6667H24.853C21.7597 52.6667 19.0663 50.8 17.9997 47.92C16.9063 45.04 17.7063 41.8667 20.0263 39.8134L41.333 21.1734C42.613 20.0534 42.6397 18.5334 42.2663 17.4934C41.8663 16.4534 40.853 15.3334 39.1463 15.3334H31.9997C30.9063 15.3334 29.9997 14.4267 29.9997 13.3334C29.9997 12.24 30.9063 11.3334 31.9997 11.3334H39.1463C42.2397 11.3334 44.933 13.2 45.9997 16.08C47.093 18.96 46.293 22.1334 43.973 24.1867L22.6663 42.8267C21.3863 43.9467 21.3597 45.4667 21.733 46.5067C22.133 47.5467 23.1463 48.6667 24.853 48.6667H31.9997C33.093 48.6667 33.9997 49.5734 33.9997 50.6667Z' fill={fill} />
    </svg>
  )
}

export default DirectionsIcon
