import { types } from '../types/types'
import { whiteLabel } from '../whiteLabel'
import i18next from 'i18next'
import {
  REACT_APP_WHITE_LABEL,
  REACT_APP_HARDCODED_TITLE,
  REACT_APP_HARDCODED_META_DESCRIPTION,
  REACT_APP_APP_NAME
} from '../constants/config'

export const uiReducer = (state = {
  isIOSApp: navigator?.userAgent?.includes('UalabeeLite=true') || window.location.search.includes('UalabeeLite=true') || sessionStorage.getItem('UalabeeLite') === 'true' || false,
  loading: false,
  openMenu: false,
  globalFCMSubscribed: false,
  cityFCMSubscribed: false,
  variantFCMSubscribed: false,
  alertsFCMSubscribed: false,
  whiteLabel: whiteLabel(),
  orientation: 'to',
  selectLabel: i18next.t('search_preview.arrival_time_first_option'),
  openCovidCenter: false,
  showUsefulInformation: false,
  openCityModal: false,
  noLoginDialog: false,
  showETA: true,
  openShareModal: {
    enabled: false,
    params: undefined
  },
  openShareDrawer: {
    enabled: false,
    params: undefined,
    linkData: undefined
  },
  openSendToPhoneModal: {
    enabled: false,
    params: undefined,
    notificationBody: undefined
  },
  identifiedCity: {},
  alerts: null,
  latam: {
    countries: [],
    cities: []
  },
  geoLocationBlocked: false,
  userReportDrawerRef: null,
  components: {
    line: {
      enabled: false
    },
    openSmartBanner: !REACT_APP_WHITE_LABEL && !navigator?.userAgent?.includes('UalabeeLite=true') && /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
    openTopSmartBanner: !REACT_APP_WHITE_LABEL && !navigator?.userAgent?.includes('UalabeeLite=true') && /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
    openBottomSmartBanner: !REACT_APP_WHITE_LABEL && !navigator?.userAgent?.includes('UalabeeLite=true') && /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
    openSendCommentariesDialog: false,
    showMenuNotification: false,
    navbarPosition: 0,
    alertInformation: false,
    occupancyLevel: { enabled: false },
    stopImage: null,
    stopOptions: false,
    stopInformation: false,
    openMoreStopPictures: false,
    invitationMessage: {
      enabled: false
    },
    openCountries: false,
    openCities: false,
    openAlerts: false,
    openAlert: false,
    openCountryAlert: false,
    openCityAlert: false,
    cityIdentifiedDismissed: false,
    navbar: {
      enabled: true
    },
    transportFilters: {
      enabled: false
    },
    alertsAndNews: {
      enabled: false
    },
    search: {
      enabled: true
    },
    autocomplete: {
      enabled: false
    },
    lines: {
      enabled: false
    },
    tripPreview: {
      enabled: false
    },
    tripResults: {
      enabled: false
    },
    filters: {
      enabled: false
    },
    mapFilters: {
      enabled: false
    },
    stop: {
      enabled: false,
      tab_index: 0
    },
    stopSecurity: {
      enabled: false
    }
  },
  search: {
    term: '',
    createdAt: ''
  },
  mode: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
  openLoginPopup: false,
  helmet: {
    title: REACT_APP_HARDCODED_TITLE || 'Recorridos y horarios de colectivos, subtes y trenes',
    description: REACT_APP_HARDCODED_META_DESCRIPTION || 'Cómo llegar, líneas, recorridos, paradas, horarios, saldo y chat con la comunidad de usuarios de colectivos, subtes, trenes y todo el transporte público',
    ogTitle: `${REACT_APP_APP_NAME} - La app colaborativa de transporte para moverte en la ciudad de forma inteligente`,
    ogDescription: 'Cómo llegar, líneas, recorridos, paradas, horarios, saldo y chat con la comunidad de usuarios de colectivos, subtes, trenes y todo el transporte público.',
    ogImage: 'https://treep.app/img/treep-og-image.png',
    keywords: 'app de colectivos argentina, recorridos, horarios, paradas, miautobus, mi autobus, ersa, coniferal, aucor, saeta, como llego, cuando viene, bondi, colectivos, cuando pasa, cordoba, salta, santa fe, bahia blanca, la plata, villa carlos paz, alta gracia, rio cuarto, rio tercero, rosario, junin, san francisco, villa maria, cuando subo, como llegar, linea, corredor, treep, trep, treip, treepy, trepy, treipy, treepi, trepi, treipi, treepie, trepie, treipie, treepee, trepee, treipee, treepy, trepy, treipy, treepi, trepi, treipi, treepie, trepie, treipie, treepee, trepee, treipee'
  },
  ackFCMSubscribed: false,
  stopPictures: undefined,
  tripDetails: [],
  alert: {
    open: false,
    title: '',
    message: '',
    severity: 'success',
    duration: 3000
  },
  ads: {
    searchResult: [],
    rotationTimes: [],
    openAdSelected: false,
    adSelected: null
  },
  showRouteExtended: true,
  tripSelectedVehicles: [],
  searchResultClickCount: localStorage.getItem('searchResultClickCount') ? parseInt(localStorage.getItem('searchResultClickCount')) : 0,
  NPSSurveyShown: localStorage.getItem('NPSSurveyShown') ? JSON.parse(localStorage.getItem('NPSSurveyShown')) : false
}, action) => {
  switch (action.type) {
    case types.loading:
      return {
        ...state,
        loading: action.payload
      }
    case types.searchValue:
      return {
        ...state,
        search: {
          term: action.payload.term,
          createdAt: action.payload.createdAt
        }
      }
    case types.autocompleteResults:
      return {
        ...state,
        results: action.payload
      }
    case types.placesSelectedFrom:
      return {
        ...state,
        places: {
          to: state.places?.to,
          from: action.payload
        }
      }
    case types.selectDateTimeLabel:
      return {
        ...state,
        selectLabel: action.payload
      }
    case types.placesSelectedTo:
      return {
        ...state,
        places: {
          from: state.places?.from,
          to: action.payload
        }
      }
    case types.autocompleteOrientation:
      return {
        ...state,
        orientation: action.payload
      }
    case types.dateTimePickerValue:
      return {
        ...state,
        dataTimeValue: action.payload
      }
    case types.tripSelected:
      return {
        ...state,
        tripSelected: action.payload
      }
    case types.tripDetails:
      return {
        ...state,
        tripDetails: action.payload
      }
    case types.tripSelectedVehicles:
      return {
        ...state,
        tripSelectedVehicles: action.payload
      }
    case types.lineSelected:
      return {
        ...state,
        lineSelected: action.payload
      }
    case types.arrivalSelected:
      return {
        ...state,
        arrivalSelected: action.payload
      }
    case types.stopSelected:
      return {
        ...state,
        stopSelected: action.payload
      }
    case types.timestamp:
      return {
        ...state,
        timestamp: action.payload
      }
    case types.openCovidCenter:
      return {
        ...state,
        openCovidCenter: action.payload
      }
    case types.lines:
      return {
        ...state,
        lines: action.payload,
        currentLines: action.payload
      }
    case types.currentLines:
      return {
        ...state,
        currentLines: action.payload
      }
    case types.currentLinesByAgency:
      return {
        ...state,
        currentLinesByAgency: action.payload
      }
    case types.mobileMode:
      return {
        ...state,
        mobileMode: action.payload
      }
    case types.showUsefulInformation:
      return {
        ...state,
        showUsefulInformation: action.payload
      }
    case types.buttonNavbarSelected:
      return {
        ...state,
        buttonNavbarSelected: action.payload
      }
    case types.cityConfig:
      return {
        ...state,
        cityConfig: action.payload
      }
    case types.identifiedCity:
      return {
        ...state,
        identifiedCity: action.payload
      }
    case types.openCityModal:
      return {
        ...state,
        openCityModal: action.payload
      }

    case types.alerts:
      return {
        ...state,
        alerts: action.payload
      }
    case types.countries:
      return {
        ...state,
        latam: {
          ...state.latam,
          countries: action.payload
        }
      }
    case types.cities:
      return {
        ...state,
        latam: {
          ...state.latam,
          cities: action.payload
        }
      }
    case types.linesComponent:
      return {
        ...state,
        components: {
          ...state.components,
          lines: {
            enabled: action.payload
          }
        }
      }
    case types.transportFiltersComponent:
      return {
        ...state,
        components: {
          ...state.components,
          navbar: {
            enabled: action.payload
          }
        }
      }
    case types.alertsAndNewsComponent:
      return {
        ...state,
        components: {
          ...state.components,
          alertsAndNews: action.payload
        }
      }
    case types.invitationMessage:
      return {
        ...state,
        components: {
          ...state.components,
          invitationMessage: action.payload
        }
      }
    case types.navbar:
      return {
        ...state,
        components: {
          ...state.components,
          navbar: {
            enabled: action.payload
          }
        }
      }
    case types.search:
      return {
        ...state,
        components: {
          ...state.components,
          search: {
            enabled: action.payload
          }
        }
      }
    case types.routes:
      return {
        ...state,
        routes: action.payload
      }
    case types.tripPreview:
      return {
        ...state,
        components: {
          ...state.components,
          tripPreview: {
            enabled: action.payload
          }
        }
      }
    case types.tripResults:
      return {
        ...state,
        components: {
          ...state.components,
          tripResults: {
            enabled: action.payload
          }
        }
      }
    case types.autocomplete:
      return {
        ...state,
        components: {
          ...state.components,
          autocomplete: {
            enabled: action.payload
          }
        }
      }
    case types.filters:
      return {
        ...state,
        components: {
          ...state.components,
          filters: {
            enabled: action.payload
          }
        }
      }
    case types.mapFilters:
      return {
        ...state,
        components: {
          ...state.components,
          mapFilters: {
            enabled: action.payload
          }
        }
      }
    case types.stop:
      return {
        ...state,
        components: {
          ...state.components,
          stop: {
            enabled: action.payload
          }
        }
      }
    case types.security:
      return {
        ...state,
        components: {
          ...state?.components,
          stopSecurity: action.payload
        }
      }
    case types.mode:
      return {
        ...state,
        mode: action.payload
      }

    case types.tripResponse:
      return {
        ...state,
        tripResponse: action.payload
      }
    case types.noLoginDialog:
      return {
        ...state,
        noLoginDialog: action.payload
      }
    case types.arrivals:
      return {
        ...state,
        arrivals: action.payload
      }
    case types.openAlertInformation:
      return {
        ...state,
        components: {
          ...state.components,
          alertInformation: action.payload
        }
      }
    case types.openMoreStopPictures:
      return {
        ...state,
        components: {
          ...state?.components,
          openMoreStopPictures: action.payload
        }
      }
    case types.openStopArrivalTimeInformation:
      return {
        ...state,
        components: {
          ...state?.components,
          stopInformation: action.payload
        }
      }
    case types.openStopOptions:
      return {
        ...state,
        components: {
          ...state?.components,
          stopOptions: action.payload
        }
      }
    case types.openOccupancyLevel:
      return {
        ...state,
        components: {
          ...state?.components,
          occupancyLevel: action.payload
        }
      }
    case types.stopPictures:
      return {
        ...state,
        stopPictures: action.payload
      }
    case types.openLoginPopup:
      return {
        ...state,
        openLoginPopup: action.payload
      }
    case types.openShareModal:
      return {
        ...state,
        openShareModal: action.payload
      }
    case types.openShareDrawer:
      return {
        ...state,
        openShareDrawer: action.payload
      }
    case types.openSendToPhoneModal:
      return {
        ...state,
        openSendToPhoneModal: action.payload
      }
    case types.openCountries:
      return {
        ...state,
        components: {
          ...state?.components,
          openCountries: action.payload
        }
      }
    case types.openCities:
      return {
        ...state,
        components: {
          ...state?.components,
          openCities: action.payload
        }
      }
    case types.currentFilters:
      return {
        ...state,
        currentFilters: action.payload
      }
    case types.openCountryAlert:
      return {
        ...state,
        components: {
          ...state?.components,
          openCountryAlert: action.payload
        }
      }
    case types.menu:
      return {
        ...state,
        openMenu: action.payload
      }
    case types.userReportDrawerRef:
      return {
        ...state,
        userReportDrawerRef: action.payload
      }
    case types.navbarPosition:
      return {
        ...state,
        components: {
          ...state?.components,
          navbarPosition: action.payload
        }
      }
    case types.cityInfo:
      return {
        ...state,
        components: {
          ...state?.components,
          city_info: action.payload
        }
      }
    case types.cityInfoSelected:
      return {
        ...state,
        components: {
          ...state?.components,
          city_info_selected: action.payload
        }
      }
    case types.stopImage:
      return {
        ...state,
        components: {
          ...state?.components,
          stopImage: action.payload
        }
      }
    case types.isIosApp:
      return {
        ...state,
        isIosApp: action.payload
      }
    case types.showMenuNotification:
      return {
        ...state,
        components: {
          ...state?.components,
          showMenuNotification: action.payload
        }
      }
    case types.openSendCommentariesDialog:
      return {
        ...state,
        components: {
          ...state?.components,
          openSendCommentariesDialog: action.payload
        }
      }
    case types.alert:
      return {
        ...state,
        alert: action.payload
      }
    case types.openSmartBanner:
      return {
        ...state,
        components: {
          ...state?.components,
          openSmartBanner: action.payload
        }
      }
    case types.openTopSmartBanner:
      return {
        ...state,
        components: {
          ...state?.components,
          openTopSmartBanner: action.payload
        }
      }
    case types.openBottomSmartBanner:
      return {
        ...state,
        components: {
          ...state.components,
          openBottomSmartBanner: action.payload
        }
      }

    case types.ads:
      return {
        ...state,
        ads: {
          ...state.ads,
          ...action.payload
        }
      }
    case types.adSelected: {
      return {
        ...state,
        ads: {
          ...state.ads,
          adSelected: action.payload
        }
      }
    }
    case types.openAdSelected: {
      return {
        ...state,
        ads: {
          ...state.ads,
          openAdSelected: action.payload
        }
      }
    }

    case types.setAdList:
      return {
        ...state,
        ads: {
          ...state.ads,
          searchResult: action.payload
        }
      }
    case types.showRouteExtended:
      return {
        ...state,
        showRouteExtended: action.payload
      }
    case types.showETA:
      return {
        ...state,
        showETA: action.payload
      }
    case types.autoCompleteStations:
      return {
        ...state,
        autoCompleteStations: action.payload
      }
    case types.searchResultClickCount:
      localStorage.setItem('searchResultClickCount', state.searchResultClickCount + 1)
      return {
        ...state,
        searchResultClickCount: state.searchResultClickCount + 1
      }
    case types.NPSSurveyShown:
      localStorage.setItem('NPSSurveyShown', JSON.stringify(action.payload))
      return {
        ...state,
        NPSSurveyShown: action.payload
      }
    default:
      return state
  }
}
