import React, { useCallback, useEffect, useRef, useState } from 'react'
import Map, { AttributionControl, GeolocateControl, Layer, Marker, Popup, Source, useMap } from 'react-map-gl'
import maplibregl from 'maplibre-gl'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import {
  getMapStops,
  handleMove,
  handleReportClick,
  handleStopClick,
  setBikeStationSelected,
  setViewState
} from '../../actions/map'
import { logEvent } from '../../firebase/firebase-config'
import { getAuth } from 'firebase/auth'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  handleClickAd,
  setFromPlacesSelected,
  setOpenMapFilters,
  setToPlacesSelected,
  setTripResponse,
  setTripSelected
} from '../../actions/ui'
import { Box, Button, Divider, IconButton, Typography, useMediaQuery } from '@mui/material'
import { AddRounded, ErrorRounded, FilterListRounded, RemoveRounded } from '@mui/icons-material'
import { theme } from '../../theme/theme'
import { MapFilters } from '../../components/MapFilters'
import { getDistanceFromLatLonInKm, handleClickRefillMarker } from '../../utils'
import RefillIcon from '../../img/refill_icon.svg'
import { useTranslation } from 'react-i18next'
import useQuery from '../../hooks/useQuery'
import { getPlaceByLatLng } from '../../db/getPlaceByLatLng'
import { AlertsDrawer } from '../../components/AlertsDrawer'
import Alert from '../../components/Alert'
import CitiesDrawer from '../../components/CitiesDrawer'
import { IdentifiedCityModal } from '../../components/IdentifiedCityModal'
import Menu from '../../components/Menu'
import { BikeStationsDrawer } from '../../components/BikeStationsDrawer'
import { CityChange } from '../../components/Desktop/CityChange'
import CountriesDrawer from '../../components/CountriesDrawer'
import { Alerts } from '../../components/Desktop/Alerts'
import i18next from '../../i18n'
import { getArrivalsRealTime } from '../../db/getArrivalsRealTime'
import { getVehiclePositionsByRoute } from '../../db/getVehiclePositionsByRoute'
import realtimeIcon from '../../img/real-time-badge.svg'
import arrowURL from '../../img/arrow.png'
import { REACT_APP_PALLET_PRIMARY_MAIN_COLOR, REACT_APP_TILES_HOST } from '../../constants/config'

/** <MapContainer/>
 * @description - TODO: add description
 * @param {Object} mapProps
 * @param {Boolean} showUserPosition - @default: true
 * @param {Boolean} showStopsNearby
 * @param {Object | Null} map
 * @param {ChildNode | Array} children
 * @param {String} width
 * @param {String} height
 * @returns {JSX.Element}
 * @constructor
 */
export const MapContainer = ({
  showUserPosition = true,
  showStopsNearby,
  children,
  width = '100vw',
  height = '100dvh',
  geoControlProps,
  showMapDevices,
  attributionPosition = 'bottom-right',
  showDestinationPopup,
  ...mapProps
}) => {
  const { pathname } = useLocation()
  const mapRef = useRef(null)

  const dispatch = useDispatch()
  const mobile = useMediaQuery('(max-width:480px)')

  const { map } = useMap()
  const geoControlRef = useRef(null)

  const bounds = useSelector(state => state?.map?.bounds)
  const userPosition = useSelector(state => state?.user?.userPosition)
  const openBottomSmartBanner = useSelector(state => state?.ui?.components.openBottomSmartBanner)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const viewState = useSelector(state => state?.map?.viewState)
  const [popup, setPopup] = useState({
    open: false,
    lngLat: null
  })
  // eslint-disable-next-line no-unused-vars
  const [attributionPositionState, setAttributionPositionState] = useState(attributionPosition)

  const [maxBounds, setMaxBounds] = useState(null)

  const onMove = useCallback(event => {
    dispatch(setViewState(event.viewState))
  }, [])

  useEffect(() => {
    if (!cityConfig) return

    const sw = new maplibregl.LngLat(cityConfig.map?.map_southwest_lng, cityConfig.map?.map_southwest_lat)
    const ne = new maplibregl.LngLat(cityConfig.map?.map_northeast_lng, cityConfig.map?.map_northeast_lat)

    const bounds = new maplibregl.LngLatBounds(sw, ne)
    setMaxBounds(bounds)
  }, [cityConfig])

  const onMapLoad = useCallback(event => {
    const map = event.target

    map.loadImage(arrowURL, (error, image) => {
      if (error) {
        console.error('err image', error)
        return
      }
      if (!map.hasImage('arrow')) map.addImage('arrow', image, { sdf: true })
    })

    dispatch(getMapStops())
  }, [])

  return (
    <>
      <Map
        id='map'
        onLoad={onMapLoad}
        ref={mapRef}
        mapLib={maplibregl}
        onContextMenu={(e) => {
          if (showDestinationPopup) {
            setPopup({
              open: true,
              lngLat: e.lngLat
            })
          }
        }}
        onMoveEnd={(e) => dispatch(handleMove(e, bounds, map, showStopsNearby))}
        onMove={onMove}
        {...viewState}
        reuseMaps
        maxBounds={maxBounds}
        style={{ width, height, minWidth: width }}
        mapStyle={`${REACT_APP_TILES_HOST}/styles/klokantech-basic/style.json`}
        {...mapProps}
        attributionControl={false}
      >

        <MapPopup
          map={map}
          open={popup.open}
          setPopup={setPopup}
          lngLat={popup.lngLat}
        />

        {showUserPosition && bounds && userPosition && bounds.contains(new maplibregl.LngLat(userPosition.lng, userPosition.lat)) &&
          <GeolocateControl
            ref={geoControlRef}
            trackUserLocation={false}
            showAccuracyCircle={false}
            position='bottom-right'
            style={{
              position: 'absolute',
              bottom: mobile
                ? openBottomSmartBanner && pathname === '/' ? '174px' : '74px'
                : showStopsNearby ? '260px' : '177px',
              right: mobile ? '4px' : '34px',
              ...geoControlProps?.styles
            }}
            positionOptions={{
              enableHighAccuracy: true,
              timeout: 6000
            }}
            onError={(e) => {
              console.error(e)
            }}
            onGeolocate={(e) => {
              const { latitude, longitude } = e.coords
              map?.flyTo({
                center: [longitude, latitude],
                zoom: 18
              })
            }}
          />}

        {map &&
          <>
            {children}
          </>}

        <AttributionControl
          position={attributionPositionState || 'bottom-right'}
          customAttribution={'<a about="_blank" href="https://www.openstreetmap.org/copyright">© OpenStreetMap contributors</a>'}
        />
      </Map>
      {showMapDevices &&
        <MapDevices
          map={map}
          showStopsNearby={showStopsNearby}
        />}
    </>
  )
}

/** <CustomMap/>
 * @description - TODO: add description
 * @param {Boolean} showStopsNearby
 * @param {Boolean} showUserReports
 * @param {Boolean} showUserPositionLocationButton
 * @param {Boolean} showDraggableDestinationMarkers
 * @param {Boolean} showMapDevices
 * @param {Boolean} showUserPosition
 * @param {Boolean} showBikeStations
 * @returns {JSX.Element}
 */

export const CustomMap = ({
  showStopsNearby = true,
  showUserReports = true,
  showDraggableDestinationMarkers = true,
  showMapDevices = true,
  showUserPosition = true,
  showBikeStations = true,
  height,
  attributionPosition = 'bottom-right',
  geoControlProps,
  showAdMarkers = false,
  showDestinationPopup = true
}) => {
  const { map } = useMap()

  const mobile = useMediaQuery('(max-width:480px)')
  const alertsAndNews = useSelector(state => state?.ui?.components?.alertsAndNews)
  const { enabled, trips } = useSelector(state => state?.map?.subwayAndTrainNetworks)

  return (
    <>
      <MapContainer
        height={height}
        showDestinationPopup={showDestinationPopup}
        attributionPosition={attributionPosition}
        showUserPosition={showUserPosition}
        showStopsNearby={showStopsNearby}
        showMapDevices={showMapDevices}
        geoControlProps={geoControlProps}
      >
        {showStopsNearby && <StopsNearby />}

        {showUserReports && <UserReports map={map} />}

        <RefillPoints />

        {showAdMarkers &&
          <SearchResultAds map={map} />}

        {showDraggableDestinationMarkers &&
          <>
            <DraggableMarkerA />
            <DraggableMarkerB />
          </>}

        {showBikeStations && <BikeStations />}

        {enabled && trips.length > 0 && <SubwayAndTrainNetworksPolylines />}
      </MapContainer>

      <IdentifiedCityModal />
      {mobile
        ? (
          <>
            <Alert map={map} />
            <CountriesDrawer map={map} />
            <CitiesDrawer />
            <AlertsDrawer map={map} />
          </>
          )
        : (
          <>
            <CityChange />
            {alertsAndNews?.enabled && <Alerts map={map} />}
          </>
          )}
      <BikeStationsDrawer />
      <Menu />
    </>
  )
}

/** <CustomMarker/>
 * @description - TODO: add description
 * @param {Number} lat
 * @param {Number} lng
 * @param {JSX.Element} icon
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const CustomMarker = ({ zIndex, lat, lng, icon, ...props }) => {
  return (
    <Marker
      style={{
        cursor: 'pointer',
        zIndex: zIndex || 0
      }}
      longitude={lng}
      latitude={lat}
      {...props}
    >
      {icon}
    </Marker>
  )
}

/** <CustomPolyline/>
 * @description - TODO: add description
 * @param {Array} positions
 * @param {String} color
 * @param {Number} width
 * @param {Array} dashArray
 * @returns {JSX.Element}
 * @constructor
 */
export const CustomPolyline = ({
  positions = [],
  color = '',
  width = 0,
  dashArray = [0, 0]
}) => {
  const data = {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'LineString',
      coordinates: positions
    }
  }

  const layerStyle = {
    id: 'route',
    type: 'line',
    source: 'route',
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-color': color,
      'line-width': width,
      'line-dasharray': dashArray
    }
  }

  return (
    <Source id='my-data' type='geojson' data={data}>
      <Layer {...layerStyle} />
    </Source>
  )
}

/** <MarkersGroup/>
 * @description - TODO: add description
 * @param {Array} positions
 * @param {JSX.Element} icon
 * @returns {JSX.Element}
 * @constructor
 */

export const MarkersGroup = ({ positions = [], icon }) => {
  return (
    <>
      {positions.map((position, index) => (
        <CustomMarker
          key={index}
          lat={position[0]}
          lng={position[1]}
          icon={icon}
        />
      ))}
    </>
  )
}

/** <PolylineWithSenseArrow />
 * @description - TODO: add description
 * @returns {JSX.Element}
 * @constructor
 */
export const PolylineWithSenseArrow = ({
  lineCoordinates = [],
  color = ''
}) => {
  const data = {
    type: 'FeatureCollection',
    features: [{
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: lineCoordinates
      }
    }]
  }

  const routeLayer = {
    id: 'route',
    type: 'line',
    source: 'mapDataSourceId',
    filter: ['==', '$type', 'LineString'],
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-color': color,
      'line-width': 8
    }
  }

  const arrowsLayer = {
    id: 'arrows',
    type: 'symbol',
    source: 'routes',
    layout: {
      'symbol-placement': 'line',
      'icon-image': 'arrow',
      'icon-rotate': -90,
      'icon-rotation-alignment': 'map',
      'icon-allow-overlap': true,
      'icon-ignore-placement': true
    },
    paint: {
      'icon-color': color
    }
  }

  return (
    <Source id='routes' type='geojson' data={data}>
      <Layer {...routeLayer} />
      <Layer {...arrowsLayer} />
    </Source>
  )
}

export const StopsNearby = () => {
  const auth = getAuth()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const user = auth.currentUser

  const stops = useSelector(state => state?.map?.markers?.stops)
  const zoom = useSelector(state => state?.map.zoom)
  const userPosition = useSelector(state => state?.user?.userPosition)

  return (
    <>
      {stops?.map((group) => {
        if (group?.enabled) {
          return group?.stops?.filter(stop => stop).map((stop, index) => {
            return (
              <CustomMarker
                key={index}
                lng={stop.stop_lon}
                lat={stop.stop_lat}
                onClick={() => dispatch(handleStopClick(stop, userPosition, logEvent, user.uid, navigate))}
                icon={
                  <Box sx={{ padding: '10px', position: 'relative' }}>
                    {zoom > 16
                      ? (
                        <>
                          <img style={{ width: '20px', height: '20px' }} src={stop?.transport_type?.icon} alt='stop' />

                          {stop?.reports_affected?.length >= 1 &&
                            <ErrorRounded
                              sx={{
                                color: '#f05454',
                                backgroundColor: '#fff',
                                width: '15px',
                                height: '15px',
                                borderRadius: '50%',
                                position: 'absolute',
                                top: 3.5,
                                right: 3.5
                              }}
                            />}
                        </>
                        )
                      : (
                        <div style={{
                          display: 'block',
                          width: '10px',
                          height: '10px',
                          backgroundColor: stop?.transport_type?.color,
                          borderRadius: '100%',
                          border: '1px solid #ffffff'
                        }}
                        />
                        )}
                  </Box>
                }
              />
            )
          })
        }
      })}
    </>
  )
}

export const UserReports = ({
  map
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const auth = getAuth()
  const user = auth.currentUser

  const language = window.navigator.language.toLowerCase()

  const userReports = useSelector(state => state?.map?.markers?.userReports)
  const userPosition = useSelector(state => state?.user?.userPosition)
  const zoom = useSelector(state => state?.map?.zoom)
  const userReportDrawerRef = useSelector(state => state?.ui.userReportDrawerRef)
  const alertTypes = useSelector(state => state?.ui?.alertTypes)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)

  return (
    <>
      {userReports.enabled && userReports.reports?.map((report, index) => {
        let size

        if (zoom > 16) {
          size = 25
        } else if (zoom <= 15 && zoom >= 13) {
          size = 20
        } else {
          size = 10
        }

        return (
          <CustomMarker
            key={index}
            lng={report.lng}
            lat={report.lat}
            onClick={() => {
              dispatch(handleReportClick(
                report,
                language,
                alertTypes,
                cityConfig,
                userPosition,
                user.uid,
                logEvent,
                map,
                userReportDrawerRef,
                navigate
              ))
            }}
            icon={
              <>
                {
                  zoom >= 13
                    ? (
                      <>
                        <img
                          style={{
                            width: `${zoom > 16 ? 25 : 20}px`,
                            height: `${zoom > 16 ? 25 : 20}px`,
                            borderRadius: '100%',
                            border: '1px solid #ffffff'
                          }}
                          src={report?.icon}
                          alt='report'
                        />
                        {report?.status_id === 1
                          ? (
                            <img
                              style={{
                                width: '40%',
                                position: 'absolute',
                                top: '-3px',
                                right: 0,
                                border: '1px solid #ffffff',
                                borderRadius: '100%',
                                backgroundColor: '#99cc00',
                                padding: '2px'
                              }}
                              src='https://firebasestorage.googleapis.com/v0/b/miautobusv3.appspot.com/o/assets%2Fic_check_white_24px.svg?alt=media&token=015c7920-d912-477e-9ad3-1e961a793507'
                              alt='badge'
                            />
                            )
                          : (
                            <img
                              style={{
                                width: '40%',
                                position: 'absolute',
                                top: '-3px',
                                right: 0,
                                border: '1px solid #ffffff',
                                borderRadius: '100%',
                                backgroundColor: '#ffbb33',
                                padding: '2px'
                              }}
                              src='https://firebasestorage.googleapis.com/v0/b/miautobusv3.appspot.com/o/assets%2Fic_pending_white.svg?alt=media&token=afee0675-f259-46fd-bb44-3f170d05bd0d'
                              alt='badge'
                            />
                            )}
                      </>
                      )
                    : (
                      <span
                        style={{
                          display: 'block',
                          width: `${size}px`,
                          height: `${size}px`,
                          opacity: report?.status_id === 0 ? 0.5 : 1,
                          backgroundColor: report?.status_id === 0 ? '#ffbb33' : '#EF9A9A',
                          borderRadius: '100%',
                          border: '1px solid #ffffff'
                        }}
                      />
                      )
                }
              </>
            }
          />
        )
      })}
    </>
  )
}

export const RefillPoints = () => {
  const auth = getAuth()
  const user = auth.currentUser

  const refillPoints = useSelector(state => state?.map?.markers?.refillPoints)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const userPosition = useSelector(state => state?.user?.userPosition)
  const zoom = useSelector(state => state?.map.zoom)

  return (
    <>
      {refillPoints?.enabled && refillPoints?.points?.map((marker, index) => {
        let size

        if (zoom > 16) {
          size = 25
        } else if (zoom <= 15 && zoom >= 13) {
          size = 20
        } else {
          size = 10
        }

        return (
          <CustomMarker
            key={index}
            lng={marker.longitude}
            lat={marker.latitude}
            onClick={() => handleClickRefillMarker(marker, logEvent, userPosition, user.uid, cityConfig)}
            icon={
              zoom <= 16
                ? (
                  <span
                    style={{
                      display: 'block',
                      width: '10px',
                      height: '10px',
                      backgroundColor: '#ffbf55',
                      borderRadius: '100%',
                      border: '1px solid #ffffff'
                    }}
                  />
                  )
                : (
                  <img
                    style={{ width: `${size}px` }}
                    src={RefillIcon}
                    alt='refill'
                  />
                  )
            }
          />
        )
      })}
    </>
  )
}

export const BikeStations = () => {
  const dispatch = useDispatch()

  const bikeStations = useSelector(state => state?.map?.markers?.bikeStations)
  const zoom = useSelector(state => state?.map?.zoom)
  const center = useSelector(state => state?.map?.center)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)

  const [stations, setStations] = useState([])

  useEffect(() => {
    const bikesFilter = bikeStations?.stations?.filter((station) => getDistanceFromLatLonInKm(station.latitude, station.longitude, center[0], center[1]) < 0.8)
    setStations(bikesFilter)
  }, [center, zoom, bikeStations])

  return (
    <>
      {bikeStations?.enabled && zoom >= 15 &&
        <>
          {stations?.map((marker, index) => (
            <Marker
              key={index}
              longitude={marker.longitude}
              latitude={marker.latitude}
              style={{ cursor: 'pointer' }}
              onClick={() => dispatch(setBikeStationSelected(marker))}
            >
              <div style={{ position: 'relative' }}>
                <img
                  style={{ width: '20px', height: '20px' }} src={cityConfig.config.bike_icon}
                  alt='bike station icon'
                />
                <span style={{
                  display: 'flex',
                  boxSizing: 'border-box',
                  fontSize: '8px',
                  color: '#ffffff',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'absolute',
                  top: '-5px',
                  width: 16,
                  height: 16,
                  padding: '4px',
                  right: '-5px',
                  border: '1px solid #ffffff',
                  borderRadius: '100%',
                  backgroundColor: marker?.free_bikes === 0 ? '#707070' : '#ffbb33'
                }}
                >
                  {marker?.free_bikes}
                </span>
              </div>
            </Marker>
          ))}
        </>}
    </>
  )
}

export const VehiclesRealtime = () => {
  const tripSelected = useSelector(state => state?.ui?.tripSelected)
  const tripDetails = useSelector(state => state?.ui?.tripDetails)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)

  return (
    <>
      {tripSelected?.legs
        ?.filter(leg => leg.mode !== 'WALK')
        ?.map((leg, index) => {
          const detail = tripDetails?.find(item => item?.route_id === leg.routeId)

          if (!detail) return <></>

          const directionId = detail?.direction_id
          const cityId = cityConfig.city_id
          const routeId = leg.routeId

          return (
            <Vehicles
              key={index}
              directionId={directionId}
              cityId={cityId}
              routeId={routeId}
              routeType={leg?.routeType}
              agencyId={leg?.agencyId}
              routeName={leg?.routeShortName || leg?.routeLongName}
            />
          )
        })}
    </>
  )
}

const Vehicles = ({
  directionId,
  cityId,
  routeId,
  routeType,
  routeName,
  agencyId
}) => {
  const tripDetails = useSelector(state => state?.ui?.tripDetails)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)

  const [currentVehicles, setCurrentVehicles] = useState([])

  useEffect(() => {
    if (!tripDetails || !cityConfig) return

    if (cityConfig?.config.realtime_hub_enabled) {
      getVehiclesOfRealtimeHub(cityId, routeId, directionId)
        .then(result => setCurrentVehicles(result))
        .catch(e => console.error(e))
    } else if (cityConfig?.config.realtime_arrivals_enabled) {
      getVehiclesOfThirdParties(cityId, routeType, routeName, directionId, agencyId)
        .then(result => setCurrentVehicles(result))
        .catch(e => console.error(e))
    }
  }, [tripDetails])

  return (
    <>
      {currentVehicles
        ?.filter(vehicle => vehicle?.timestamp)
        ?.map((vehicle, index) => {
          return (
            <VehicleRealtime
              key={index}
              lat={vehicle.coordinates[1]}
              lng={vehicle.coordinates[0]}
              timestamp={vehicle.timestamp}
              icon={
                <div
                  style={{
                    position: 'relative'
                  }}
                >
                  <img
                    style={{
                      position: 'absolute',
                      top: '-10px',
                      left: '-10px',
                      width: '12px',
                      height: '12px'
                    }}
                    src={realtimeIcon}
                    alt='realtime icon'
                  />
                  <img
                    style={{
                      width: '25px',
                      height: '25px'
                    }}
                    src={cityConfig?.transport_types?.find(transport => transport.route_type === routeType)?.icon}
                    alt='realtime vehicle icon'
                  />
                </div>
              }
            />
          )
        })}
    </>
  )
}

export const getVehiclesOfRealtimeHub = async (cityId, routeId, directionId) => {
  const params = {
    city_id: cityId,
    route_id: routeId,
    direction_id: directionId
  }

  try {
    const response = await getVehiclePositionsByRoute(params)

    return response?.result?.map(vehicle => {
      return {
        id: vehicle?.vehicle?.vehicle?.id,
        coordinates: [vehicle?.vehicle?.position.longitude, vehicle?.vehicle?.position.latitude],
        properties: vehicle?.proximaParada || null,
        timestamp: vehicle?.vehicle?.timestamp,
        currentStopSequence: vehicle?.vehicle?.currentStopSequence
      }
    })
  } catch (e) {
    console.error(e)
  }
}

export const getVehiclesOfThirdParties = async (cityId, routeType, routeName, directionId, agencyId) => {
  switch (cityId) {
    case 21: { // Buenos Aires, Argentina
      if (routeType !== 3) return []

      // Bus
      const params = {
        city_id: cityId,
        url: 'https://apitransporte.buenosaires.gob.ar/colectivos/vehiclePositionsSimple?agency_id=' + agencyId,
        api_buenos_aires: true,
        method: 'GET'
      }

      try {
        const response = await getArrivalsRealTime(params)
        return response
          ?.filter(vehicle => routeName === vehicle.route_short_name && directionId === vehicle.direction)
          ?.map(vehicle => {
            return {
              coordinates: [vehicle?.longitude, vehicle?.latitude],
              properties: vehicle?.trip_headsign,
              timestamp: vehicle?.timestamp
            }
          })
      } catch (e) {
        console.error(e)
        return []
      }
    }
    case 30: { // Montevideo, Uruguay
      const params = {
        city_id: cityId,
        url: 'https://www.montevideo.gub.uy/buses/rest/stm-online',
        body: {
          empresa: '-1',
          lineas: [routeName]
        },
        method: 'POST'
      }

      try {
        const response = await getArrivalsRealTime(params)

        return response?.result?.features
          ?.filter(vehicle => routeName === vehicle.properties.linea)
          ?.map(vehicle => {
            return {
              coordinates: vehicle.geometry.coordinates,
              properties: vehicle.properties
            }
          })
      } catch (e) {
        console.error(e)
        return []
      }
    }
    default:
      return []
  }
}

export const VehicleRealtime = ({ lat, lng, icon, timestamp }) => {
  const { t, i18n } = useTranslation()
  const [openPopup, setOpenPopup] = useState(false)

  return (
    <>
      <CustomMarker
        lng={lng}
        lat={lat}
        onClick={() => setOpenPopup(prevState => !prevState)}
        icon={icon}
      />
      {openPopup &&
        <Popup
          longitude={lng}
          latitude={lat}
          anchor='bottom'
          offset={16}
          closeButton
          closeOnClick={false}
          focusAfterOpen={false}
        >
          <Box
            sx={{
              padding: '8px',
              borderRadius: '8px',
              background: '#fff',
              textAlign: 'center'
            }}
          >
            {t('routes.reported_by', { prop1: moment(timestamp).locale(i18n.language).fromNow(true) })}
          </Box>
        </Popup>}
    </>
  )
}

export const MapDevices = ({
  map,
  showStopsNearby
}) => {
  const auth = getAuth()
  const dispatch = useDispatch()

  const user = auth.currentUser

  const mapFilters = useSelector(state => state?.ui?.components?.mapFilters)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const userData = useSelector(state => state?.user?.userData)
  const userPosition = useSelector(state => state?.user?.userPosition)

  const handleClickFilters = () => {
    dispatch(setOpenMapFilters(!mapFilters?.enabled))

    const eventParams = {
      lat: userPosition?.lat ? userPosition.lat : null,
      lng: userPosition?.lng ? userPosition.lng : null,
      user_id: user.uid,
      city_id: cityConfig?.city_id.toString(),
      os: 'web',
      user_birthday_timestamp: userData?.birthday?.long_value || null, // Long
      user_gender: userData?.gender || null // String
    }

    // Send events to analytics
    logEvent('open_map_filters_menu', eventParams)
  }

  const handleZoomIn = () => {
    map.zoomIn()
  }

  const handleZoomOut = () => {
    map.zoomOut()
  }

  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: '88px',
        right: '40px',
        zIndex: 1,
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        flexDirection: 'column',
        '@media (max-width:480px)': {
          display: 'none'
        }
      }}
    >

      {/* tripSelected && pathname.includes('trip_share') &&
        <ExpandRouteButton
          map={map}
        />
      */}

      {showStopsNearby &&
        <Box
          sx={{
            position: 'relative'
          }}
        >
          <IconButton
            sx={{
              width: '48px',
              height: '48px',
              backgroundColor: '#fff',
              marginBottom: '24px',
              boxShadow: '0px 3px 6px #22283129',
              '&:hover': {
                backgroundColor: '#fff'
              }
            }}
            onClick={handleClickFilters}
            disabled={!map}
          >
            <FilterListRounded sx={{ color: theme.palette.icons.secondary.main }} />
          </IconButton>
          {mapFilters?.enabled && <MapFilters />}
        </Box>}

      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          backgroundColor: 'white',
          width: '48px',
          boxShadow: '0px 3px 6px #22283129',
          borderRadius: '24px'
        }}
      >
        <IconButton
          onClick={handleZoomIn}
          disabled={!map}
        >
          <AddRounded sx={{ color: theme.palette.icons.secondary.main }} />
        </IconButton>
        <Divider />
        <IconButton
          onClick={handleZoomOut}
          disabled={!map}
        >
          <RemoveRounded sx={{ color: theme.palette.icons.secondary.main }} />
        </IconButton>
      </Box>

    </Box>
  )
}

export const DraggableMarkerA = ({ draggable = true, showAdress = true }) => {
  const auth = getAuth()
  const user = auth.currentUser

  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const query = useQuery()
  const { pathname } = useLocation()
  const { map } = useMap()

  const from = useSelector(state => state?.ui?.places?.from)
  const to = useSelector(state => state?.ui?.places?.to)
  const userPosition = useSelector(state => state?.user?.userPosition)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const userData = useSelector(state => state?.user?.userData)
  const orientation = useSelector(state => state?.ui?.orientation)

  const mobile = useMediaQuery('(max-width:480px)')

  const markerA = (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='45'
      height='45'
      viewBox='0 0 107 138.25'
    >
      <g>
        <g
          fill={`${REACT_APP_PALLET_PRIMARY_MAIN_COLOR}`}
        >
          <circle fill='#fff' cx='53.73' cy='53.78' r='43.92' />
          <path
            d='M47.23.2a54.51,54.51,0,0,0-31.9,16.38c-18.4,19-20.4,46.94-5.1,68.51,5.6,7.79,15.4,15.38,24.2,18.57l6.2,2.4,5.5,11.18c3.5,7,6.1,11.19,7.1,11.19s3.7-4.29,7.2-11.49c3.9-7.68,6.3-11.48,7.4-11.48.9,0,5.2-1.8,9.6-4A54.56,54.56,0,0,0,105.33,68c2.4-8.39,2.2-22-.5-30.56-5.1-16-19-30-34.7-34.85C63.73.7,52.33-.5,47.23.2ZM68.93,51c4.8,14.78,8.8,27.07,8.8,27.17s-3.1.3-6.9.3c-6.8,0-6.9-.1-8-3l-1-3H44.93l-.7,3c-.7,3-.7,3-7.6,3-3.8,0-6.9-.3-6.9-.8s4.1-12.79,9.1-27.47L48,23.47l6.1.3,6,.3Z'
          />
          <path d='M52.43,51.53c-3.3,7.79-3.3,8,1.3,8s4.5.4,1.5-8L53.93,48Z' />
          <path d='M51.13,134.92a2.59,2.59,0,0,0,.4,2.7c1.5,1.49,4.4.1,4-1.9C55.13,133.42,51.93,132.82,51.13,134.92Z' />
        </g>
      </g>
    </svg>
  )

  return (
    <>
      <CustomMarker
        lat={from?.latLng?.lat || 0}
        lng={from?.latLng?.lng || 0}
        draggable={draggable}
        onDragStart={(event) => {
          const marker = event.target
          if (marker != null) {
            const { lat, lng } = marker.getLngLat()

            const eventParams = {
              os: 'web', // String
              marker_lat: lat, // Double
              marker_lng: lng, // Double
              lat: userPosition ? userPosition.lat : null, // Double
              lng: userPosition ? userPosition.lng : null, // Double
              city_id: cityConfig?.city_id.toString(), // String
              user_id: user.uid, // String
              user_birthday_timestamp: userData?.birthday?.long_value || null, // Long
              user_gender: userData?.gender || null // String
            }

            // Send events to analytics
            logEvent('origin_marker_drag_start', eventParams)
          }
        }}
        onDragEnd={(event) => {
          const marker = event.target

          if (marker != null) {
            const { lat, lng } = marker.getLngLat()

            // Set name and latlng origin ( marker A ) in redux
            dispatch(setFromPlacesSelected({
              name: t('home.searching'),
              latLng: { lat, lng }
            }))

            const eventParams = {
              os: 'web', // String
              marker_lat: lat, // Double
              marker_lng: lng, // Double
              lat: userPosition ? userPosition.lat : null, // Double
              lng: userPosition ? userPosition.lng : null, // Double
              city_id: cityConfig?.city_id.toString(), // String
              user_id: user.uid, // String
              user_birthday_timestamp: userData?.birthday?.long_value || null, // Long
              user_gender: userData?.gender || null // String
            }

            // Send events to analytics and mixpanel
            logEvent('origin_marker_drag_end', eventParams)

            getPlaceByLatLng({ lat, lng }, cityConfig)
              .then((response) => {
                dispatch(setFromPlacesSelected(response))
                if (pathname === '/' && to?.name !== t('hint_location_B') && to?.name !== t('home.searching') || pathname === '/search' === 'from') {
                  dispatch(handleSearch(navigate))

                  const p1 = new maplibregl.LngLat(lng, lat)
                  const p2 = new maplibregl.LngLat(to.latLng.lng, to.latLng.lat)

                  const bounds = new maplibregl.LngLatBounds(p1, p2)

                  map?.fitBounds(bounds, {
                    padding: {
                      top: 80,
                      left: mobile ? 16 : 482,
                      right: 80,
                      bottom: 80
                    }
                  })
                } else if (pathname === '/search' && orientation === 'from') {
                  navigate('/destination-preview')
                }
              })
              .catch(e => {
                console.error(e)
                // TODO: show alert informing the user that the requested address could not be obtained
                dispatch(setFromPlacesSelected({
                  name: t('hint_location_A'),
                  latLng: {
                    lat: cityConfig?.map.origin_marker_lat,
                    lng: cityConfig?.map.origin_marker_lng
                  }
                }))
              })

            // Update url params
            if (window.location.pathname.includes('trip_share') && !mobile) {
              const urlParams = {
                city_id: parseInt(query.get('ciudad_id')),
                lat_from: query.get('latd'),
                lng_from: query.get('lngd'),
                lat_to: query.get('lath'),
                lng_to: query.get('lngh'),
                radius: Number(query.get('radio')),
                self_search: query.get('self_search') === 'true',
                transport_types: query.get('transport_types'),
                locale: query.get('locale'),
                arrive_by: query.get('arrive_by') === 'true',
                invited_by: query.get('invitedby'),
                referrer_name: query.get('referrerName'),
                timestamp: query.get('timestamp'),
                transfer: query.get('transfer')
              }

              navigate(`/trip_share?ciudad_id=${urlParams?.city_id}&latd=${lat}&lngd=${lng}&lath=${urlParams.lat_to}&lngh=${urlParams.lng_to}&radio=${urlParams.radius}&invitedby=${urlParams.invited_by}&referrerName=${urlParams.referrer_name}&logEvent=${'share'}&transport_types=${urlParams.transport_types}&locale=${urlParams.locale}&self_search=${urlParams.self_search}&transfer=${urlParams.transfer}&arrive_by=${urlParams.arrive_by}&timestamp=${urlParams.timestamp}`)
              dispatch(setTripResponse(undefined))
              dispatch(setTripSelected(undefined))
            }
          }
        }}
        icon={markerA}
      />
      {showAdress &&

        <Popup
          longitude={from?.latLng?.lng || 0} latitude={from?.latLng?.lat || 0}
          anchor='bottom'
          offset={30}
          closeButton={false}
          closeOnClick={false}
        >
          <Box
            sx={{
              backgroundColor: '#fff',
              padding: '8px',
              borderRadius: '8px',
              boxShadow: '0 3px 6px #22283129'
            }}
          >
            {from?.name}
          </Box>
        </Popup>}

    </>
  )
}
export const DraggableMarkerB = ({ draggable = true, showAdress = true, showFromArrivalTime = false }) => {
  const auth = getAuth()
  const user = auth.currentUser

  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const query = useQuery()
  const { pathname } = useLocation()
  const { map } = useMap()

  const userPosition = useSelector(state => state?.user?.userPosition)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const userData = useSelector(state => state?.user?.userData)
  const to = useSelector(state => state?.ui?.places?.to)
  const from = useSelector(state => state?.ui?.places?.from)
  const tripSelected = useSelector(state => state?.ui?.tripSelected)
  const orientation = useSelector(state => state?.ui?.orientation)

  const timestamp = new Date(tripSelected?.endTime)
  const time = timestamp ? moment(timestamp, 'h:mm A').format('HH:mm') : null
  const mobile = useMediaQuery('(max-width:480px)')

  const markerB = (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 107 137.43'
      width='45'
      height='45'
    >
      <g>
        <g fill={`${REACT_APP_PALLET_PRIMARY_MAIN_COLOR}`}>
          <path
            fill='#fff'
            d='M67.94,6.6c-8.1-2.5-20.5-2.6-28.2-.1C4.14,17.9-7.06,60.9,18.54,87.7c5.3,5.6,9.5,8.2,24.3,14.8.8.4,3.3,4.4,5.6,9s4.5,8.2,5,8.1,2.9-4,5.4-8.8c4.2-8.1,4.8-8.7,8.7-9.9,7.7-2.1,16.2-7.5,21.9-13.5,9.1-9.9,13.4-20.7,13.4-33.9A48.93,48.93,0,0,0,67.94,6.6Z'
          />
          <path
            d='M47.23.2a54.48,54.48,0,0,0-31.9,16.4c-18.4,19-20.4,47-5.1,68.6,5.6,7.8,15.4,15.4,24.2,18.6l6.2,2.4,5.5,11.2c3.5,7,6.1,11.2,7.1,11.2s3.7-4.3,7.2-11.5c3.9-7.7,6.3-11.5,7.4-11.5.9,0,5.2-1.8,9.6-4a54.58,54.58,0,0,0,27.9-33.5c2.4-8.4,2.2-22-.5-30.6-5.1-16-19-30-34.7-34.9C63.73.7,52.33-.5,47.23.2Zm20.6,6.4a48.93,48.93,0,0,1,34.9,46.9c0,13.2-4.3,24-13.4,33.9-5.7,6-14.2,11.4-21.9,13.5-3.9,1.2-4.5,1.8-8.7,9.9-2.5,4.8-4.9,8.7-5.4,8.8s-2.8-3.6-5-8.1-4.8-8.6-5.6-9c-14.8-6.6-19-9.2-24.3-14.8C-7.17,60.9,4,17.9,39.63,6.5,47.33,4,59.73,4.1,67.83,6.6Z'
          />
          <path
            d='M37.73,52.7V79.9l10.9-.5c9.5-.4,11.4-.8,15.4-3.1,9.4-5.6,12.1-16.4,6.1-25-2.7-3.8-3-5-2.7-9.8.8-10.9-5.7-15.8-20.9-15.9h-8.8Zm17.5-12.6c0,1.4-.8,2.1-2.7,2.3-2.4.3-2.8,0-2.8-2.3s.4-2.6,2.8-2.3C54.43,38,55.23,38.7,55.23,40.1Zm2.4,16c5.1,2.6,5.1,7.4.1,10a14.65,14.65,0,0,1-5.5,1.5c-2.4,0-2.5-.2-2.5-6.5s.1-6.5,2.5-6.5A13.72,13.72,0,0,1,57.63,56.1Z'
          />
          <path d='M51.13,134.1a2.59,2.59,0,0,0,.4,2.7c1.5,1.5,4.4.1,4-1.9C55.13,132.6,51.93,132,51.13,134.1Z' />
        </g>
      </g>
    </svg>
  )

  return (
    <>
      <CustomMarker
        lat={to?.latLng?.lat || 0}
        lng={to?.latLng?.lng || 0}
        draggable={draggable}
        icon={markerB}
        onDragStart={(event) => {
          const marker = event.target

          if (marker !== null) {
            const { lat, lng } = marker.getLngLat()

            const eventParams = {
              os: 'web', // String
              marker_lat: lat, // Double
              marker_lng: lng, // Double
              lat: userPosition ? userPosition.lat : null, // Double
              lng: userPosition ? userPosition.lng : null, // Double
              city_id: cityConfig?.city_id?.toString(), // String
              user_id: user.uid, // String
              user_birthday_timestamp: userData?.birthday?.long_value || null, // Long
              user_gender: userData?.gender || null // String
            }

            // Send events to analytics
            logEvent('destination_marker_drag_start', eventParams)
          }
        }}
        onDragEnd={(event) => {
          const marker = event.target

          if (marker !== null) {
            const { lat, lng } = marker.getLngLat()

            const eventParams = {
              marker_lat: lat, // Double
              marker_lng: lng, // Double
              lat: userPosition ? userPosition.lat : null, // Double
              lng: userPosition ? userPosition.lng : null, // Double
              os: 'web', // String
              user_birthday_timestamp: userData?.birthday?.long_value || null, // Long
              user_gender: userData?.gender || null, // String
              user_id: user.uid, // String
              city_id: cityConfig?.city_id.toString() // String
            }

            // Send events to analytics and mixpanel
            logEvent('destination_marker_drag_end', eventParams)

            getPlaceByLatLng({
              lat,
              lng
            }, cityConfig)
              .then((response) => {
                dispatch(setToPlacesSelected(response))

                if (pathname === '/' && from?.name !== t('hint_location_A') && from?.name !== t('home.searching')) {
                  dispatch(handleSearch(navigate))

                  const p1 = new maplibregl.LngLat(from.latLng.lng, from.latLng.lat)
                  const p2 = new maplibregl.LngLat(lng, lat)

                  const bounds = new maplibregl.LngLatBounds(p1, p2)

                  map?.fitBounds(bounds, {
                    padding: {
                      top: 80,
                      left: mobile ? 16 : 482,
                      right: 80,
                      bottom: 80
                    }
                  })
                } else if (pathname === '/search' && orientation === 'to') {
                  navigate('/destination-preview')
                }
              })
              .catch(e => {
                console.error(e)
                // TODO: show alert informing the user that the requested address could not be obtained
                dispatch(setToPlacesSelected({
                  name: i18next.t('hint_location_B'),
                  latLng: { lat: cityConfig.map.destination_marker_lat, lng: cityConfig.map.destination_marker_lng }
                }))
              })

            // Update trips and url params
            if (window.location.pathname.includes('trip_share') && !mobile) {
              const urlParams = {
                city_id: parseInt(query.get('ciudad_id')),
                lat_from: query.get('latd'),
                lng_from: query.get('lngd'),
                lat_to: query.get('lath'),
                lng_to: query.get('lngh'),
                radius: Number(query.get('radio')),
                self_search: query.get('self_search') === 'true',
                transport_types: query.get('transport_types'),
                locale: query.get('locale'),
                arrive_by: query.get('arrive_by') === 'true',
                invited_by: query.get('invitedby'),
                referrer_name: query.get('referrerName'),
                timestamp: query.get('timestamp'),
                transfer: query.get('transfer')
              }

              navigate(`/trip_share?ciudad_id=${urlParams?.city_id}&latd=${urlParams.lat_from}&lngd=${urlParams.lng_from}&lath=${lat}&lngh=${lng}&radio=${urlParams.radius}&invitedby=${urlParams.invited_by}&referrerName=${urlParams.referrer_name}&logEvent=${'share'}&transport_types=${urlParams.transport_types}&locale=${urlParams.locale}&self_search=${urlParams.self_search}&transfer=${urlParams.transfer}&arrive_by=${urlParams.arrive_by}&timestamp=${urlParams.timestamp}`)
              dispatch(setTripResponse(undefined))
              dispatch(setTripSelected(undefined))
            }

            // Set name and latlng destination ( marker B ) in redux
            dispatch(setToPlacesSelected({
              name: t('home.searching'),
              latLng: { lat, lng }
            }))
          }
        }}
      />

      {showAdress &&
        <Popup
          longitude={to?.latLng?.lng || 0}
          latitude={to?.latLng?.lat || 0}
          anchor='bottom'
          offset={30}
          closeButton={false}
          closeOnClick={false}
        >
          <Box
            sx={{
              padding: '8px',
              borderRadius: '8px',
              background: '#fff',
              boxShadow: '0 3px 6px #22283129'
            }}
          >
            {to?.name}
          </Box>
        </Popup>}

      {showFromArrivalTime && tripSelected &&
        <>
          <Popup
            longitude={to?.latLng?.lng || 0}
            latitude={to?.latLng?.lat || 0}
            anchor={mobile ? 'bottom' : 'right'}
            offset={25}
            closeButton={false}
            closeOnClick={false}
            className='popup-arrival-time'
          >

            {t('results.you_arrive_at')}<br /><span>{time}</span>
          </Popup>
        </>}
    </>
  )
}

export const SearchResultAds = ({ map }) => {
  const dispatch = useDispatch()

  const ads = useSelector(state => state.ui.ads.searchResult)
  const zoom = useSelector(state => state?.map.zoom)

  let size

  if (zoom > 16) {
    size = 25
  } else if (zoom <= 15 && zoom >= 13) {
    size = 20
  } else {
    size = 10
  }

  return (
    <>
      {map && ads?.map(ad => {
        return ad?.markers
          .filter(marker => !marker.hidden)
          .map((marker, index) => (
            <CustomMarker
              key={index}
              lat={marker.lat}
              lng={marker.lng}
              icon={
                <>
                  {zoom >= 13
                    ? (
                      <img
                        style={{
                          width: `${size}px`,
                          height: `${size}px`,
                          border: '3px solid #ffbb2a',
                          borderRadius: '50%'
                        }}
                        src={ad?.marker_icon_uri}
                        alt='ad marker'
                      />
                      )
                    : (
                      <span
                        style={{
                          display: 'block',
                          width: '10px',
                          height: '10px',
                          borderRadius: '50%',
                          backgroundColor: '#ffbb2a',
                          border: '1px solid #ffffff'
                        }}
                      />
                      )}
                </>
              }
              onClick={() => dispatch(handleClickAd({ ...ad, marker }))}
            />
          ))
      })}
    </>
  )
}

export const StopMarker = ({
  stop,
  handleClickStop,
  color
}) => {
  return (
    <CustomMarker
      lat={stop?.stop_lat}
      lng={stop?.stop_lng || stop?.stop_lon}
      onClick={() => handleClickStop(stop)}
      icon={
        <div style={{
          width: '14px',
          height: '14px',
          backgroundColor: '#fff',
          borderRadius: '100%',
          border: `3px solid ${color}`
        }}
        />
      }
    />
  )
}

const handleSearch = (navigate) => {
  return (dispatch, getState) => {
    const {
      ui: { cityConfig, places: { from, to }, selectLabel, dataTimeValue }
    } = getState()

    const localFilters = JSON.parse(localStorage.getItem('resultFilters') || '[]')

    const currentFilters = localFilters?.find((filters) => filters?.city_id === cityConfig?.city_id)

    if (!currentFilters) return

    const currentTransports = currentFilters?.transportation_types?.filter(type => type.checked)

    const transports = currentTransports?.map(type => type.transport_type_id)?.toString()

    const transfer = currentFilters?.type_of_route === 'only-directs' ? false : currentFilters?.type_of_route === 'only-transfers' ? true : null

    const params = {
      city_id: cityConfig?.city_id,
      lat_from: from?.latLng.lat,
      lng_from: from?.latLng.lng,
      lat_to: to?.latLng?.lat,
      lng_to: to?.latLng?.lng,
      radius: currentFilters?.walks * 100,
      transfer: transfer || null,
      self_search: true,
      transport_types: transports,
      locale: 'es',
      lat_from_station: null,
      lng_from_station: null,
      lat_to_station: null,
      lng_to_station: null,
      arrive_by: selectLabel ? selectLabel !== i18next.t('search_preview.arrival_time_first_option') : false,
      timestamp: dataTimeValue || null
    }

    navigate(`/trip_share?ciudad_id=${params.city_id}&latd=${params.lat_from}&lngd=${params.lng_from}&lath=${params.lat_to}&lngh=${params.lng_to}&radio=${params.radius}&invitedby=${params?.invited_by || null}&referrerName=${params?.referrer_name || null}&logEvent=${'share'}&transport_types=${params.transport_types}&locale=${params.locale}&self_search=${params.self_search}&transfer=${params.transfer}&arrive_by=${params.arrive_by}&timestamp=${params.timestamp}`)
  }
}

export const MapPopup = ({
  map,
  open,
  lngLat,
  setPopup
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const query = useQuery()
  const { pathname } = useLocation()
  const mobile = useMediaQuery('(max-width:480px)')

  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const to = useSelector(state => state?.ui?.places?.to)
  const from = useSelector(state => state?.ui?.places?.from)

  const urlParams = {
    city_id: parseInt(query.get('ciudad_id')),
    lat_from: query.get('latd'),
    lng_from: query.get('lngd'),
    lat_to: query.get('lath'),
    lng_to: query.get('lngh'),
    radius: Number(query.get('radio')),
    self_search: query.get('self_search') === 'true',
    transport_types: query.get('transport_types'),
    locale: query.get('locale'),
    arrive_by: query.get('arrive_by') === 'true',
    invited_by: query.get('invitedby'),
    referrer_name: query.get('referrerName'),
    timestamp: query.get('timestamp'),
    transfer: query.get('transfer')
  }

  /*

map?.on('contextmenu', function (e) {
  const popLocation = e.lngLat

  if (popLocation) {
    setPopupLocation(popLocation)
  }
  setOpenPopup(true)
})

map?.on('click', function (e) {
  console.log(e)

  if (mobile) {
    const popLocation = e.latlng

    if (popLocation) {
      setPopupLocation(popLocation)
    }
    setOpenPopup(true)
  }
})
  */

  /** handleClick
   * @description ---
   * @param {String} orientation
   * @returns {Promise<void>}
   */

  const handleClick = async (orientation) => {
    if (!orientation) return

    if (pathname.includes('trip_share') && !mobile) {
      setPopup({ open: false, lngLat: null })
      navigate(`/trip_share?ciudad_id=${urlParams?.city_id}&latd=${urlParams.lat_from}&lngd=${urlParams.lng_from}&lath=${lngLat?.lat}&lngh=${lngLat?.lng}&radio=${urlParams.radius}&invitedby=${urlParams.invited_by}&referrerName=${urlParams.referrer_name}&logEvent=${'share'}&transport_types=${urlParams.transport_types}&locale=${urlParams.locale}&self_search=${urlParams.self_search}&transfer=${urlParams.transfer}&arrive_by=${urlParams.arrive_by}&timestamp=${urlParams.timestamp}`)
    }

    try {
      const newPlace = await getPlaceByLatLng({
        lat: lngLat?.lat,
        lng: lngLat?.lng
      }, cityConfig)

      switch (orientation) {
        case 'to': {
          dispatch(setToPlacesSelected(newPlace))

          if (pathname === '/' && from?.name !== t('hint_location_A') && from?.name !== t('home.searching')) {
            dispatch(handleSearch(navigate))

            const p1 = new maplibregl.LngLat(from.latLng.lng, from.latLng.lat)
            const p2 = new maplibregl.LngLat(lngLat?.lng, lngLat?.lat)

            const bounds = new maplibregl.LngLatBounds(p1, p2)

            map?.fitBounds(bounds, {
              padding: {
                top: 80,
                left: mobile ? 16 : 482,
                right: 80,
                bottom: 80
              }
            })
          }

          break
        }
        case 'from': {
          dispatch(setFromPlacesSelected(newPlace))

          if (pathname === '/' && to?.name !== t('hint_location_B') && to?.name !== t('home.searching')) {
            dispatch(handleSearch(navigate))

            const p1 = new maplibregl.LngLat(lngLat?.lng, lngLat?.lat)
            const p2 = new maplibregl.LngLat(to.latLng.lng, to.latLng.lat)

            const bounds = new maplibregl.LngLatBounds(p1, p2)

            map?.fitBounds(bounds, {
              padding: {
                top: 80,
                left: mobile ? 16 : 482,
                right: 80,
                bottom: 80
              }
            })
          }
          break
        }
      }

      setPopup({ open: false, lngLat: null })
    } catch (e) {
      setPopup({ open: false, lngLat: null })
      console.error(e)
    }
  }

  return (
    <>
      {open &&
        <Popup
          longitude={lngLat.lng}
          latitude={lngLat.lat}
          closeButton={false}
          closeOnClick={false}
          focusAfterOpen={false}
        >
          <Box
            sx={{
              padding: '25px 20px 10px 20px',
              borderRadius: '15px',
              backgroundColor: '#fff',
              boxShadow: '0 3px 6px #22283129'
            }}
          >

            <Typography
              sx={{
                fontSize: '16px',
                textAlign: 'center',
                margin: '0px 0px 10px',
                color: '#000',
                fontWeight: 'bold'
              }}
            >
              {t('home.origin_or_destination')}
            </Typography>
            <Typography
              sx={{
                fontSize: '12px',
                textAlign: 'center',
                margin: '0px 0px 15px',
                color: '#000',
                fontWeight: 300
              }}
            >
              {t('home.dialog_destination_title')}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Button
                onClick={() => handleClick('from')}
                sx={{
                  color: 'rgb(0, 0, 0) !important',
                  textTransform: 'none !important'
                }}
              >
                {mobile ? t('home.short_from_here') : t('home.from_here')}
              </Button>
              <Button
                onClick={() => handleClick('to')}
                sx={{
                  color: 'rgb(0, 0, 0) !important',
                  textTransform: 'none !important'
                }}
              >
                {mobile ? t('home.short_to_here') : t('home.to_here')}
              </Button>
            </Box>

          </Box>

        </Popup>}
    </>
  )
}

/** <SubwayAndTrainNetworksPolylines />
 * @description - TODO: add description
 * @returns {JSX.Element}
 * @constructor
 */
export const SubwayAndTrainNetworksPolylines = () => {
  const auth = getAuth()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const user = auth.currentUser
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const zoom = useSelector(state => state?.map.zoom)
  const userPosition = useSelector(state => state?.user?.userPosition)

  const { trips } = useSelector(state => state?.map?.subwayAndTrainNetworks)

  return (
    trips.map((route, index) => {
      const data = {
        type: 'FeatureCollection',
        features: [{
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: route?.polylineDecoded.map((point) => {
              return [point[1], point[0]]
            })
          }
        }]
      }

      const routeLayer = {
        id: `route-${index}`,
        type: 'line',
        source: `mapDataSourceId-${index}`,
        filter: ['==', '$type', 'LineString'],
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': `#${route?.route_color}`,
          'line-width': zoom > 16 ? 4 : (zoom > 15 ? 6 : 8)
        }
      }

      return (
        <div
          key={index}
        >
          <Source key={index} id={`mapDataSourceId-${index}`} type='geojson' data={data}>
            <Layer {...routeLayer} />
          </Source>
          {route?.stops?.map((stop, index) => {
            return (
              <CustomMarker
                key={index}
                lat={stop?.stop_lat}
                lng={stop?.stop_lon}
                onClick={() => dispatch(handleStopClick(stop, userPosition, logEvent, user.uid, navigate))}
                icon={
                  <Box sx={{ padding: '10px', position: 'relative' }}>
                    {zoom > 16 &&
                      (
                        <img style={{ width: '20px', height: '20px' }} src={cityConfig.transport_types.find(type => type.route_type === route.route_type).icon} alt='stop' />
                      )}
                    {zoom >= 15 && zoom < 16 &&
                      <div style={{
                        display: 'block',
                        width: '15px',
                        height: '15px',
                        backgroundColor: `#${route?.route_color}`,
                        borderRadius: '100%',
                        border: '2px solid #ffffff'
                      }}
                      />}
                  </Box>
              }
              />
            )
          }
          )}
        </div>
      )
    }
    ))
}
